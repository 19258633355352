import React, {useEffect } from 'react'
import {useStore} from 'react-context-hook'

const CategoriesDetected = () => {
    const [uploadResponse] = useStore('uploadResponse', {});
    const [detectedCategories , setdetectedCategories] = useStore('detectedCategories', [])
    const [categoryIndex, setCategoryIndex] = useStore('categoryIndex', 0)
    const [, setCategorySelected] = useStore('categorySelected', 0)
    const [, setSelectedAttributesObject] = useStore('selectedAttributesObject', {})
    const [VSresponse] = useStore('VSresponse', {})
    const [, setVSsmallImages] = useStore('VSsmallImages', null)
    // console.log(categoryIndex)
    

    useEffect(()=>{
        if (uploadResponse && uploadResponse.responseJson !== null && uploadResponse.responseJson !== undefined && uploadResponse.responseStatus === 200)
        {
            const category = uploadResponse.responseJson.result.map((element) => element.category)
            setdetectedCategories(category)
        } else if ( Array.isArray(uploadResponse) && uploadResponse.length > 0 ) {
            const category = uploadResponse.map((element) => element.category);
            setdetectedCategories(category);
        }
    }, [uploadResponse])
    
    useEffect(() => {
        if (uploadResponse && uploadResponse.responseJson !== null && uploadResponse.responseJson !== undefined && uploadResponse.responseStatus === 200)
            {
                if(uploadResponse.responseJson.result[0] !== null && uploadResponse.responseJson.result[0] !== undefined){
                let obj = {} 
                const kategorii = uploadResponse.responseJson.result.map((el) => el.attributes)
                Object.entries(kategorii[categoryIndex]).forEach((item, index) => {
                    
                    // console.log(item)
                    item = item[1]
                    
                    if(Object.keys(obj).includes(item.attribute_type)) {
                        obj[item.attribute_type].push([item.attribute])
                    } else {
                        obj[item.attribute_type] = []
                        obj[item.attribute_type].push(item.attribute)
                    }
                    
                })
                setSelectedAttributesObject(obj)
                setCategorySelected(detectedCategories[categoryIndex])
                }
            }
    }, [detectedCategories, categoryIndex, uploadResponse])

    useEffect(() => {
        if(VSresponse && VSresponse.responseJson !== null && VSresponse.responseJson !== undefined && VSresponse.responseStatus === 200) { 
            setVSsmallImages(null)
            const VScategoriesDetected = VSresponse.responseJson.detected_categories.map((cat) => cat.category)
            setdetectedCategories(VScategoriesDetected)
        }
        else if ( Array.isArray(VSresponse) && VSresponse.length > 0 ) {
            setVSsmallImages(null)
            const VScategoriesDetected = VSresponse.map((cat) => cat.category)
            setdetectedCategories(VScategoriesDetected);
        }
    }, [VSresponse ])

    useEffect(() => {
        if(VSresponse && VSresponse.responseJson !== null && VSresponse.responseJson !== undefined && VSresponse.responseStatus === 200) { 
            // console.log(VSresponse)
            const VSsmallImages = VSresponse.responseJson.detected_categories.map((cat) => cat.result)[categoryIndex]
            setVSsmallImages(VSsmallImages)
            setCategorySelected(detectedCategories[categoryIndex])
        } else if (Array.isArray(VSresponse) && VSresponse.length > 0 ) {
            // console.log(VSresponse.res)
            const VSsmallImages = VSresponse.map((cat) => cat.result)[categoryIndex]
            // console.log(VSsmallImages)
            setVSsmallImages(VSsmallImages)
            setCategorySelected(detectedCategories[categoryIndex])
        }
        
    }, [detectedCategories ,VSresponse, categoryIndex ])

    
    const handler = (ix) => {
        setCategoryIndex(ix)
        setCategorySelected(detectedCategories[ix])
        // setVSsmallImages(null)
    }   

    
    return (
        
        <div className="container segment cat-detected"  id="categories">
            <div>
                <p className="detected-categories-p">
                    Detected Categories
                </p>
            </div>

            <div>
                <ul className="detected-categories-tab">
                    {
                        detectedCategories && detectedCategories.length > 0 ? detectedCategories.map((e, ix) => {
                        return (<li key={ix}  onClick={(e) => handler(ix) } className={ix === categoryIndex ? "detected-categories-li" : 'detected-categories-li-active'}>
                            <span className={ix === categoryIndex ? "numberSpan" : "numberSpan-active"}>{ ix + 1 }</span> 
                            <span>{e}</span>
                        </li>)
                        }) 
                        : null
                    }
                </ul>
            </div>
        </div>
        
    )
}

export default CategoriesDetected
