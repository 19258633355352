import React from "react";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate  } from "react-router-dom";

import PrivateRoute from './components/validation/validation';
import Home from "./components/Home/Home";
import { withStore } from 'react-context-hook';
import Login from './components/Login/Login'
import "./sass/main.scss";
import './App.css';

function App() {
  const [logedIn, setLogedIn] = useState(false)
  const token = JSON.parse(localStorage.getItem("VStoken"));
  return (
    <>
        <Router>
        <Routes>
          <Route path="/"  element={<Login/>} />

          <Route path="/visual-search" element={
            <PrivateRoute>
                  <Home/>
            </PrivateRoute>
          } />
        </Routes>
      </Router>
      
      {/* <Footer /> */}
    </>
  );
}

export default withStore(App);
// <Routes>
// <Route path="/" element={<Login/>} />
//  <Route path="/visual-search" element={<Home/>}/>

// <Route path="" element={token && token["access_token"] && token["access_token"].length > 0 ?  <Navigate to ="/visual-search" /> :
// <Navigate to ="/" />}/>

 
// </Routes>
