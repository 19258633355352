// import '../endpoints/accountSettings'
//import accountSettings from '../endpoints/accountSettings';
 
import {
    Navigate,
  } from 'react-router-dom';
  
  //My Private Route
 const PrivateRoute = ({ children }) => {
    const auth = JSON.parse(localStorage.getItem('VStoken'));
    return auth ? children : <Navigate to="/" />;
  };
export default PrivateRoute