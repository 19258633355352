import React from 'react';
import Carousel from './Carousel/Carousel'
const ProductCard = (props) => {
    const {
        productId,
        primaryImage,
        product_url,
        extra_images,
        setImgLoaded,
        ix
    } = props;

    const displayID = (id) => {
        if (id.length < 25) {
            return id
        } else {
            return id.substring(0, 25)
        }
    }

    return (
        <div className="cardWrapper" key={productId} id={productId}>
            <div className={`ui card cardExtra cardExtra-vs` }>
                <div 
                className="image products"
                key={productId}
                >
                   {/* <img   src={primaryImage} alt="Primary_image" className={`imgExtra vs`}/> */}
                   <Carousel 
                        primary_image={primaryImage} 
                        productId={productId} 
                        extra_images={extra_images} 
                        setImgLoaded={setImgLoaded}
                        product_url={product_url}
                   />
                </div>
                <div className="content" key={ix}>
                    <div className="header headerExtra" key={ix}>
                     <div className=' id-flex'  ><div className="productId" key={ix}>Product ID: {productId}</div></div>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductCard;