import React from 'react';
import UploadImg from '../Upload/UploadImg'
import DemoImgVSstatic from '../DemoImgVS/DemoImgVSstatic'
const Home = () => {
   
    return (
        <div className='container header'>
            <UploadImg/>
            <DemoImgVSstatic/>
        </div>
    )
}

export default Home
