import React, {useEffect, useState}from 'react';

import Logo from '../../assets/Logo-DemoPixyle-SVG.svg'
import logout from "../../endpoints/logout";
import logoutIcon from '../../assets/OOjs_UI_icon_upload_2.svg'
const Navbar =() => {
    // const [openCustomDemoForm, setOpenCustomDemoForm] = useStore('book demo',false)
    const [versionChange,setVersionChange] = useState(4)
    const token=JSON.parse(localStorage.getItem("VStoken"));
    return (
        <div className="container">
            <nav className="navbar">
                    <div className="nav-item-logo-img">
                        <a href="">
                            <img src={Logo} alt="pix-logo"  className="ui small image logo-img"/>
                        </a>
                    </div> 
                    <div className='vsDemo'>Visual Search Demo</div>
                    <div className='div-nav'>
                        <div className='welcome'>Welcome, About You</div>
                        <div className="left-navbar-item-logout" onClick={()=>logout(token, versionChange)}>
                        <img src={logoutIcon} alt="logout icon"  style={{transform:"rotate(180deg)"}} className="svg-gray" />
                        {/* <div className="solution-text logout">
                                Log Out
                        </div> */}
                    </div>
                 </div>
            </nav>
        </div>
      
    )
}

export default Navbar
