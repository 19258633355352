import React, {useEffect, useState}from 'react';
import login from '../../endpoints/login';
import loginIcon from '../../assets/img_login.svg'
import ErrorModal from '../Modal/ErrorModal';
const Login =(props) => { 
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const handleSubmit = (e) =>{
        e.preventDefault()
        e.stopPropagation();
        let data = {};
        data.username = username;
        data.password = password;
        console.log(data)
       
        try {
            login(data).then (async res => {
                if(!res.status){
                    localStorage.setItem("VStoken", JSON.stringify(res));
                    localStorage.setItem("userToken", JSON.stringify(username));
                    window.location.assign("/visual-search");
                    
                } else if( res.status === 429){
                    if(res.message){
                        setErrMsg("Rate Limit Exceeded. Please Try Again After Few Minutes!")
                       
                    } else{
                        setErrMsg(res.detail)
                    }
                    
                }
                else {
                    setErrMsg(res.detail)
                    // console.log(this.state.clicked)
                    }
                })
        } 
        catch(err){
            // console.log("Error: ", err)
        }
    

    }


    return (
        <div className="container login-div">
                <div className="login-form-bg">
                    <div className="login-form">
                        <div className="login-img">
                            <img src ={loginIcon} alt="Woman holding a phone illustration."/>
                        </div>
                        <div className="login-input">
                            <div className="column-direction">
                            <h1>Log In</h1>
                            <p>Upload your collections and let us do the heavy lifting</p>
                            <form className="ui form"  onSubmit={handleSubmit}>
                              <div className="ui field">
                                  <label>Username</label>
                                  <input
                                    className="formField u-mrs"
                                    type="text"
                                    id="username"
                                    name="userName"
                                    placeholder="Username"
                                    value={username} 
                                    onChange={(e)=>setUsername(e.target.value)} 
                                    required
                                  />
                                  </div>
                              <div className="ui field">
                                  <label>Password</label>
                                  <input
                                      className="formField u-mrs"
                                      type="password"
                                      id="password"
                                      name="password"
                                      placeholder="Password"
                                      onChange={(e)=>setPassword(e.target.value)} 
                                      required
                                    ></input>
                              </div>
                              <div className="field  centerThis u-mrs">
                                  <button className="round-purple-button btn" style={{padding:"0"}}>Log me in</button>
                              </div>
                            </form>
                            <div className="ui divider"></div>
                            <p>Having problems logging in? <a href="https://www.pixyle.ai/"><b>Contact Us</b></a> so we can solve it right away...</p>
                            {/* <p><b onClick={this.recoveryClick} style={{cursor:"pointer"}}>Forgot Password?</b> </p> */}
                            </div>
                        </div>
                    </div>
                </div>
        {errMsg !== '' ? <ErrorModal errMsg = {errMsg} setErrMsg = {setErrMsg} /> :null}
        </div>
      
    )

}



export default Login


