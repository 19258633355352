import React, {useState, useEffect} from 'react'
import {withStore, useStore} from 'react-context-hook'

import uploadIcon from '../../assets/uploadIcon.png'
// import {visualSearchImageURL} from '../../endpoints/getProduct'
import {vsLive} from '../../endpoints/vsLive'

import UploadingProgress from './UploadingProgress'
import Completed from './Completed'
import ErrorModal from '../Modal/ErrorModal';
import DragAndDrop from './DragAndDrop';

// import LimitExceededModal from '../Modal/LimitExceededModal'
const UploadImg =()=>{
   
    // const [attrType, setAttrType] =  useStore('attrType', {})
    const [ , setUploadedImageURL] = useStore('uploadedImageURL',null)
    const [textField, setTextField] = useState('')
    const [VSresponse, setVSresponse] = useStore('VSresponse', {})
    const [, setCategoryIndex] = useStore('categoryIndex', 0)

    const [errorMsg, setErrorMsg] = useState(null)
    const [descriptionMsg, setDescriptionMsg] = useState(null)

    const [limitExceeded, setLimitExceeded] = useState(false)

    const [uploading, setUploading] = useState(false)
    const [complete, setComplete] = useState(false)
    const [dragging, setDragging] = useStore('dragging',false);
    const token = JSON.parse(localStorage.getItem("VStoken"));
    // const [firstLoaded, setFirstLoaded] = useStore('loaded', false)

    // let history = useHistory();
    const closeResModal=()=>{
        setLimitExceeded(null);
        setErrorMsg(null);
        setUploading(false)
    }
    const onChange = (e) => setTextField(e.target.value);
    const onSearch = async (e) => {
        e.preventDefault();
        setComplete(false)
        if(textField !== '') {
            setUploading(true)
            setTextField("")
            // let dataset_id = 16590961520422451
            // let res = await visualSearchImageURL('16590961520422451',textField)
            let res = await vsLive(textField, token)
            if(res.hasError){
                setUploading(false)
                setComplete(true)
                if(res.responseJson && res.responseJson.detail){
                    setErrorMsg(res.responseJson.detail)
                   
                } else if (res.error){
                    setErrorMsg(res.error.message)
                }else{
                    setErrorMsg("No similar products found")
                }
            }
            else if(res.hasError===false){
                console.log(res)
                // res.responseJson.detected_categories.map((catRes)=>{
                //    console.log( catRes.result)

                // })
                if(res.result && res.result.length === 0) {
                    setErrorMsg("No similar products found")
                    setUploading(false)
                    setComplete(true)
                }
                else {
                    setComplete(true)
                    setTimeout(() => {
                        if(res.responseStatus == 200){
                            setCategoryIndex(0)
                            setVSresponse(res)
                            setUploadedImageURL(textField) 
                            setErrorMsg("")
                            setUploading(false)
                            setComplete(true)
                        }
                    }, 600);
                }
            }
            else{
                setErrorMsg("We are unable to process this file.")
                setUploading(false)
                setComplete(true)
            }
        } else {
            setErrorMsg('Missing URL Parametar')
        }
        
    }
    const uploadFunc =  async (file) => {
        setComplete(false)
   
        if(file ) {
            setUploading(true)
            let res = await vsLive(file, token)
                if(res && res!==null && res !== undefined && res.responseStatus !== 200) {
                    if(res.responseStatus === 204)
                        setErrorMsg("No Predictions Found")
                    else if(res.responseStatus === 429){
                        setUploading(false)
                        setComplete(false)
                        setLimitExceeded(res.responseJson.message)
                    }
                    else if(res.responseStatus === 422){
                        setUploading(false)
                        setErrorMsg('Unsupported image format ')
                        setDescriptionMsg('Please try again by uploading one of our supported image formats - JPG, PNG or TIFF')
                        }
                    else if (res.responseJson !==null && res.responseJson !== undefined && !res.responseStatus == 422 && res.responseJson.detail)
                        {setUploading(false)
                        setErrorMsg(res.responseJson.detail)}
                    else if(res.msg)
                    {    setUploading(false)
                        setErrorMsg(res.msg)}
                    else{
                        setUploading(false)
                        setErrorMsg('Timeout Error')
                        setDescriptionMsg('Server timed out, please check your internet connection and try again. If the problem doesn’t resolve contact us here.')
                    }
            }
            if(res.responseStatus==200){
                setComplete(true)
                setTimeout(() => {
                    if(res.responseStatus == 200){
                        setCategoryIndex(0)
                        setVSresponse(res)
                        setUploadedImageURL(URL.createObjectURL(file))
                        setErrorMsg("")
                        setUploading(false)
                        setComplete(true)
                    }
                }, 600);
          }
        }
    }
    
    useEffect(()=>{
        if(complete){
            setTimeout(()=>{
                setComplete(false)
               
            },800)
        }
    },[complete])
    const handleUpload = async (e) =>{
        e.preventDefault()
         let file = e.target.files[0]
         uploadFunc(file)
    }
    const handleDrop=(e)=>{
        let file = e[0];
        console.log(e)
        uploadFunc(file)
    }

    return (
        <>
        <DragAndDrop handleDrop={(e)=>handleDrop(e)}>
        {complete? <Completed/> :
        <div className='upload-container'>
        <div  accept=".jpg, .jpeg, .png, .jfif"  
                 className={`upload mobile-container-upload`} >
             <div className={ uploading || complete? ' hide ' :'show' }>
                 <div className={ uploading? 'uploadContainer hide' :'uploadContainer' }>
                 <label htmlFor  ="file" className="columnUploadedImageButton ">
                         <input type="file" name="file" id="file" accept=".jpg, .jpeg, .png, .jfif" 
                             className="inputfile" 
                             value=""
                             onChange={handleUpload}
                             /> 
                           Upload an Image  
                                 
                 </label>
                 <div className="columnUploadedImageText">or</div>
                     <form onSubmit={onSearch} className={`columnUploadedImageInput ` }>
                         <input type="text" id="name" name="name" placeholder="paste URL" className="inputLink" 
                                 value={textField} 
                                 onChange={onChange}
                         />
                         <button className={`urlButtonAutomatic `}  onClick={onSearch}>Search</button>
                     </form>    
                 </div>
                         
                         {/* <div className={`privacy-policy home `}>
                             By uploading an image you’re agreeing to <a  href="https://www.pixyle.ai/privacy" className='ppHome' >Pixyle’s Privacy Policy.</a>   
                         </div>   */}
             </div>
             {uploading? < UploadingProgress  />:null } 
             {complete ? <Completed/> : null} 
           
 
         
         </div>
        
        </div>
        }
       </DragAndDrop>
       {errorMsg ? <ErrorModal  errMsg={errorMsg} setErrMsg={setErrorMsg} /> : null}
        </>
    )
}
export default  UploadImg;