import React, {useEffect, useState} from 'react'
import {useStore} from 'react-context-hook'
import ProductCard from '../Card/ProductCard'

const VSimageGrid = () => {
    const [uploadedImageURL] = useStore('uploadedImageURL', null) // uploadedImageURL
    const [VSsmallImages] = useStore('VSsmallImages', null)
    const [categorySelected] = useStore('categorySelected', '')
    const [VSresponse, setVSresponse] = useStore('VSresponse', {})

    const [VSresponseResult, setVSresponseResult] = useState([])
    const [VSreset, setVSreset] = useState(false)
    const [categoryIndex, setCategoryIndex] = useStore('categoryIndex', 0)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [imgLoaded, setImgLoaded] = useState(false)
    // setImgLoaded={stateObject.setImgLoaded}
  
    // console.log(VSsmallsImages)
    
    const createDiv = () => {
        let boardDiv = document.createElement("div");
        return boardDiv;
    }

    const createBoundingBoxes = () => {
        var img = document.getElementById("image-upload-VS")
        let narH = document.getElementById('image-upload-VS').naturalHeight;
        let narW = document.getElementById('image-upload-VS').naturalWidth;
        // console.log('BBox enter')
        if(narH===0 || narW===0){
            // console.log('reseting here')
            let newReset = VSreset?false:true;
            setVSreset(newReset);
        }

        if(narH>0 && narW > 0) {

            var scaleFactorHeight = img.offsetHeight / narH;
            var scaleFactorWidth = img.offsetWidth / narW;

            var widthDivider = narW / img.offsetWidth;
            var heightDivider = narH / img.offsetHeight;

          
            let imgDiv = document.getElementById("uploaded-image-VS")
            var boundingBoxes=[];
            if(!scaleFactorWidth || !scaleFactorHeight || !widthDivider || !heightDivider ){
                let newReset = VSreset?false:true;
                setVSreset(newReset);
            }

            if(imgDiv.childNodes.length===1) {
                VSresponseResult.forEach((categoryObj, index)=>{
                    let coordinates = categoryObj.product_coordinates
                    // console.log(coordinates)
                    boundingBoxes.push(createDiv())
                    boundingBoxes[index].id = parseInt(index);
                    boundingBoxes[index].innerText= parseInt(index) + 1;
 
                    if(categoryIndex===parseInt(index))
                    {
                        // console.log("coordinates",coordinates)
                        boundingBoxes[index].className="bounding-box-active"

                        boundingBoxes[index].style.width= (Math.round((coordinates.xmax-coordinates.xmin)*scaleFactorWidth))+"px";
                        boundingBoxes[index].style.height= (Math.round((coordinates.ymax-coordinates.ymin)*scaleFactorHeight))+"px"
                        boundingBoxes[index].style.top=(Math.round((coordinates.ymin)/heightDivider)).toString() + "px"
                        boundingBoxes[index].style.left=(Math.round((coordinates.xmin /widthDivider))).toString()+"px"; 
                        boundingBoxes[index].style.opacity="0.7";

                    }
                    else {
                        boundingBoxes[index].className="bounding-box"
                        boundingBoxes[index].style.width = "4rem";
                        boundingBoxes[index].style.height = "4rem";
                        boundingBoxes[index].style.top=(Math.round(((coordinates.ymin+coordinates.ymax)/(2*heightDivider)))).toString()+"px"; 
                        boundingBoxes[index].style.left=(Math.round((((coordinates.xmin+coordinates.xmax)/(2 * widthDivider))))).toString()+"px"; 
                        boundingBoxes[index].style.borderRadius = "50%";
                        boundingBoxes[index].style.backgroundColor = "#8E9AAF";
                        boundingBoxes[index].style.paddingTop="1rem";
                        // boundingBoxes[index].style.position="fixed";
                        boundingBoxes[index].addEventListener("click",()=>
                        {
                            setCategoryIndex(parseInt(index));
                        })
                    }
                    imgDiv.appendChild(boundingBoxes[index])
                })
            } else if (imgDiv.childNodes.length>1) {
                for (let i = 1; i<imgDiv.childNodes.length; i++) {
                    imgDiv.removeChild(imgDiv.childNodes[i]);
                    let newReset = VSreset?false:true;
                    setVSreset(newReset);
                }
            }
        }
    }
    const createBoundingBoxesUplImg = () => {
        var img = document.getElementById("image-upload-VS")
        let narH = document.getElementById('image-upload-VS').naturalHeight;
        let narW = document.getElementById('image-upload-VS').naturalWidth;
        if(narH===0 || narW===0){
            // console.log('reseting here')
            let newReset = VSreset?false:true;
            setVSreset(newReset);
        }

        if(narH>0 && narW > 0) {

            var scaleFactorHeight = img.offsetHeight / narH;
            var scaleFactorWidth = img.offsetWidth / narW;

            var widthDivider = narW / img.offsetWidth;
            var heightDivider = narH / img.offsetHeight;

   
            let imgDiv = document.getElementById("uploaded-image-VS")
            var boundingBoxes=[];
            if(!scaleFactorWidth || !scaleFactorHeight || !widthDivider || !heightDivider ){
                let newReset = VSreset?false:true;
                setVSreset(newReset);
            }

            if(imgDiv.childNodes.length===1) {
                VSresponseResult.detected_categories.forEach((categoryObj, index)=>{
                    let coordinates = categoryObj.product_coordinates
                    boundingBoxes.push(createDiv())
                    boundingBoxes[index].id = parseInt(index);
                    boundingBoxes[index].innerText= parseInt(index) + 1;
                    
                    if(categoryIndex===parseInt(index))
                    
                    {
                        // console.log("coordinates",coordinates)
                        boundingBoxes[index].className="bounding-box-active"

                        boundingBoxes[index].style.width= (Math.round((coordinates.xmax-coordinates.xmin)*scaleFactorWidth))+"px";
                        boundingBoxes[index].style.height= (Math.round((coordinates.ymax-coordinates.ymin)*scaleFactorHeight))+"px"
                        boundingBoxes[index].style.top=(Math.round((coordinates.ymin)/heightDivider)).toString() + "px"
                        boundingBoxes[index].style.left=(Math.round((coordinates.xmin /widthDivider))).toString()+"px"; 
                        // console.log( boundingBoxes[index].style.top,  boundingBoxes[index].style.left,  boundingBoxes[index].style.width ,  boundingBoxes[index].style.height, "----------------------------")
                        // console.log(coordinates.xmax, coordinates.xmin, coordinates.ymax,coordinates.ymin,  scaleFactorWidth, scaleFactorHeight , heightDivider, widthDivider, "------------------------------------" )
                        boundingBoxes[index].style.opacity="0.7";

                    }
                    else {
                        boundingBoxes[index].className="bounding-box"
                        boundingBoxes[index].style.width = "4rem";
                        boundingBoxes[index].style.height = "4rem";
                        boundingBoxes[index].style.top=(Math.round(((coordinates.ymin+coordinates.ymax)/(2*heightDivider)))).toString()+"px"; 
                        boundingBoxes[index].style.left=(Math.round((((coordinates.xmin+coordinates.xmax)/(2 * widthDivider))))).toString()+"px"; 
                        boundingBoxes[index].style.borderRadius = "50%";
                        boundingBoxes[index].style.backgroundColor = "#8E9AAF";
                        boundingBoxes[index].style.paddingTop="1rem";
                        // boundingBoxes[index].style.position="fixed";
                        boundingBoxes[index].addEventListener("click",()=>
                        {
                            setCategoryIndex(parseInt(index));
                        })
                    }
                    imgDiv.appendChild(boundingBoxes[index])
                })
            } else if (imgDiv.childNodes.length>1) {
                for (let i = 1; i<imgDiv.childNodes.length; i++) {
                    imgDiv.removeChild(imgDiv.childNodes[i]);
                    let newReset = VSreset?false:true;
                    setVSreset(newReset);
                }
            }
        }
    }
    useEffect(()=>{
        
        if(VSresponse && Object.keys(VSresponse).length > 0 ){
            if(VSresponse.responseStatus === 200)
              {  setVSresponseResult(VSresponse.responseJson)
            } else if(VSresponse.responseStatus === null || VSresponse.responseStatus === undefined) {
                setVSresponseResult(VSresponse);
            }
        }
    }, [VSresponse])

    useEffect(() => {
        if(firstLoaded)
            setFirstLoaded(false)
    }, [uploadedImageURL])

    useEffect(()=>{
        // console.log(VSresponse, categoryIndex)
        if(VSresponseResult.length > 0 && firstLoaded){
            setTimeout(()=>{createBoundingBoxes()}, 50)
        }
        else if(VSresponseResult.detected_categories && VSresponseResult.detected_categories.length > 0 && firstLoaded){
          
            setTimeout(()=>{createBoundingBoxesUplImg()}, 50)
        }
    }, [uploadedImageURL, VSresponseResult, categoryIndex, VSreset, firstLoaded])
    // useEffect(()=>{
    //     console.log(categoryIndex)
    // },[categoryIndex])

    return (
            <div className=" container  grid-section">
                {/* <div className="ui segment vs"> */}
                    <div className="ui grid at">
                        <div className="eight wide column at p-img">
                            <div className="uploaded-image" id="uploaded-image-VS">
                                <img alt="img" className="gridImage" id="image-upload-VS" src={uploadedImageURL} onLoad={()=>{setTimeout(()=>setFirstLoaded(true), 50)}}/>
                            </div>
                            
                        </div>

                        <div className="eight wide column at s-img">
                            <div className="right-block">
                                <p className="VScategory">Similar items:</p>
                               
                            </div>
                            <div className="ui grid vs">
                                
                                {
                                    VSsmallImages ? VSsmallImages.map((img, ix) => (
                               
                                        <ProductCard 
                                            productId={img.product_code} 
                                            primaryImage={img.primary_image} 
                                            product_url={img.product_url}
                                            setImgLoaded={setImgLoaded}
                                            extra_images={img.extra_images}
                                            ix={ix}
                                        />

                                        // <div key={ix} className="five wide column vs">
                                        //     <img className="VS-grid-small-imgs" src={img} alt="Similar products found with Visual Search"/>
                                        // </div>
                                    ))

                                    :
                                    null
                                }                                   
                            </div>
                        </div>

                    </div>
                {/* </div> */}

            </div>
    )
}

export default VSimageGrid
