import React, {useState, useEffect} from 'react'
import {useStore} from 'react-context-hook'

import VSstaticImages from '../../assets/vs-json-aboutYou/vs_images-new.json'
const DemoImgVS = () => {   

    const [, setUploadedImageURL] = useStore('uploadedImageURL', null)
    const [staticImgIndex, setStaticImgIndex] = useStore('staticImgIndex',0) 
    const [categoryIndex, setCategoryIndex] = useStore('categoryIndex', 0)
    const [detectedCategories , setdetectedCategories] = useStore('detectedCategories', [])
    const [, setSelectedAttributesObject] = useStore('selectedAttributesObject', {})
    const [selectedImageName, setSelectedImageName] = useStore('selectedImageName','')
    const [, setCategorySelected] = useStore('categorySelected', '')
    const [, setuploadResponse] = useStore('uploadResponse', {});
    const [staticImgs, setStaticImgs] = useState('')
    const [, setVSsmallImages] = useStore('VSsmallImages', null)
    const [VSresponse, setVSresponse] = useStore('VSresponse', {})

    const [reseter] = useStore('reseter', false);
   

    useEffect (() =>{
        const VSheaderImages = VSstaticImages.map((img) => img.image_title)
        setStaticImgs(VSheaderImages)
        
    }, [])
    

    useEffect(() => {
        
            const staticCategory = VSstaticImages.map((img) => img.categories.map((res) => res.category))[staticImgIndex]
            setdetectedCategories(staticCategory)
        
    },[staticImgIndex, reseter])



    // Use effect only when first accessing and there is nothing selected or going back from error modal
    useEffect(()=>{
            if(VSstaticImages && selectedImageName === '' && detectedCategories.length > 0) {
                let toReturn = false;
                VSstaticImages.map((img) => {
                    if(!toReturn) {
                        img.categories.map((cat, index) => {
                            if (index === 0) {
                                toReturn = true;
                                const smallImages = cat.result.map((VSsmallImages) => VSsmallImages.primary_image)
                                setSelectedImageName(img.image_title)
                                setUploadedImageURL(`${img.image_title}`)
                                setCategorySelected(detectedCategories[categoryIndex])
                                setVSsmallImages(smallImages)
                                setVSresponse(img.categories)
                            }
                            
                        })
                    }
                })
            }
        
    }, [detectedCategories, VSstaticImages, categoryIndex])
    

    useEffect(() => {
            VSstaticImages.map((img) => {
                if(img.image_title === selectedImageName) {
                    img.categories.map((cat, index) => {
                        if(index === categoryIndex) {
                            // console.log(cat.result.map((VSsmallImages) => VSsmallImages.primary_image))
                            const smallImages = cat.result.map((VSsmallImages) => VSsmallImages.primary_image)
                            setVSsmallImages(smallImages)
                            setCategorySelected(detectedCategories[categoryIndex])
                        }
                    })
                }
            })
        
    }, [selectedImageName, categoryIndex, VSstaticImages])

    useEffect(()=>{
        setCategorySelected(detectedCategories[0])
    }, [detectedCategories])


    const handleClick = (img, ix) => {
   
        setCategoryIndex(0)
        setSelectedImageName(img)
        setUploadedImageURL(`${img}`)
        setStaticImgIndex(ix)
      
            setVSresponse({})
            VSstaticImages.forEach(staticImg => {
                if(staticImg.image_title === img){
                    // console.log(staticImg)
                    setVSresponse(staticImg.categories)
                }
            })
        
        // setuploadResponse(`images/${img}/result`)  
    }


    return (
            <div className="container segment sm-img">
                <div className="pictures-p-tag">
                    <p>Or try one of our demo images</p>
                </div>
                <div className="columnImg">
                    {
                        staticImgs ? staticImgs.map((img, ix) => {
                            return (
                                <div key={ix} className="img-div" onClick={() => handleClick(img, ix)}>
                                    <img  src={`${img}`}  className="img" alt="listed-images" />
                                </div>
                            )
                        })
                    : null}
                </div>
            </div>
    )
}

export default DemoImgVS