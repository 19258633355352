import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const PixyleCarousel = (props) => {
 
    const mapImages = () => {
        // fill up the array of all possible img urls
        let imageURLs = [];
        imageURLs.push(props.primary_image);
        if(props.extra_images!==null && props.extra_images!==undefined && props.extra_images!==[]){
            props.extra_images.forEach(img => {
                imageURLs.push(img);
            });
        }
        // map through our full array, and return div-imgs
        let images = [];
        imageURLs.forEach(url=> {
            let imgTag = 
           <div style={{height:"100%"}}  key={url}>
             <a className='linked-img' href={props.product_url?props.product_url:null} target="_blank" rel="noreferrer"  key={url}>
                 <img className={`imgExtra vs`} onLoad={()=>props.setImgLoaded(true)} src={url} alt="product.jpg" key={url}/>
            </a>
           </div>
           
           
            images.push(imgTag);
        })
        return images;
    }


    return (
      
            <Carousel className={`imgExtra vs`} infiniteLoop showThumbs={false} interval={5000} showStatus={false} key={props.productId}> 
                {(props.primary_image!==null || props.primary_image!==undefined) ? mapImages() : null}
            </Carousel>
        
    )

}
export default PixyleCarousel;
