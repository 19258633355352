import React from 'react';
import Header from '../Header/Header'
import Navbar from '../Navbar/Navbar';
import VSimageGrid from '../VSimageGrid/VSimageGrid'
import CategoriesDetected from '../CategoriesDetected/CategoriesDetected'
import Footer from '../Footer/Footer';
import {  Routes, Route, Navigate  } from "react-router-dom";
const Home = () => {
    return (
        <>
        <Navbar/>
        <div className='home'>
            <Header/>
            <CategoriesDetected/>
            <VSimageGrid/>
        </div>
        <Footer/>
        </>
    )
}

export default Home
