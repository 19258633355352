import {baseURL} from '../endpoints/baseUrl'
const logout = async (token, versionChange) => {
   
    // const token = JSON.parse(localStorage.getItem("loginToken"));
    let accessRevoke;
    let refreshRevoke;
    try {
      accessRevoke = await fetch(
        `${baseURL}${versionChange}/users/logout/access_revoke`,
        {
          method: "DELETE",
          headers: new Headers({
            Authorization: "Bearer " + token.access_token
          })
        }
      );
    } catch (error) {
      // console.log("error:", error);
    }
    try {
      refreshRevoke = await fetch(
        `${baseURL}${versionChange}/users/logout/refresh_revoke`,
        {
          method: "DELETE",
          headers: new Headers({
            Authorization: "Bearer " + token.refresh_token
          })
        }
      );
    } catch (error) {
      // console.log("error:", error);
    }

    // if (accessRevoke && refreshRevoke === 200) {
    // console.log("accessRevoke", accessRevoke, "refreshRevoke", refreshRevoke);
    localStorage.removeItem("loginToken");
    localStorage.removeItem("datasetId")
    localStorage.removeItem("selectedCategory")
    window.location.assign('/');
    // }
  };

  export default logout