import {baseURL, demoToken} from "./baseUrl";
//https://pva.pixyle.ai/v4/datasets/16590961520422451/solutions/visual-search/image
//  /v4/solutions/visual-search/image
export const vsLive = async (imageFormData, token) => {
    const formData = new FormData();
    formData.append("image", imageFormData);
    let responseStatus

    try{
        return await fetch( `${baseURL}/solutions/visual-search/image`, {
            method:'POST',
            headers: new Headers({
                "Authorization":`Bearer ${token.access_token}`
            }),
            body:formData
        })        
        .then(response => 
            {
              responseStatus = response.status;
              if (response.status === 401) {
           
            }
              // if(response.ok)
              // {
                // console.log("STATUS:", responseStatus)
                return response.json()
              // }
  
              // else ....
            })
        .then(responseJson => {
            if(responseStatus===200){
                return {responseStatus,responseJson,hasError:false} 
               }
            else return {responseStatus,responseJson,hasError:true,msg:"Unable to upload."}
        })
    } catch(error){
        return {responseStatus, error}
    }
}
